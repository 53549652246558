import React, { useState, useContext } from 'react';
import logo from '../images/logo2.jpg';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import { Navigation } from "./navigation";
import { AccountContext } from "./Account";

import UserPool from '../UserPool';
import { useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const { authenticate } = useContext(AccountContext);

    const login = (e) => {
        e.preventDefault();

        authenticate(email, password)
            .then(data => {
                console.log("Logged in!", data)
                setErrorMessage('');
                setTimeout(function () {
                    navigate('/');
                }, 100);
            })
            .catch(err => {
                if (err.name === "UserNotConfirmedException") {
                    const userData = {
                        Username: email,
                        Pool: UserPool
                    };

                    const cognitoUser = new CognitoUser(userData);

                    cognitoUser.resendConfirmationCode((err, result) => {
                        if (err) {
                            console.log('error', err.message);
                        }
                        console.log('call result: ' + JSON.stringify(result));
                    });
                    setTimeout(function () {
                        navigate('/confirm', { state: { email: email } });
                    }, 500);
                } else {
                    console.log("Failed to login!", err)
                    setErrorMessage(err.message);
                }
            })
    };

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <form onSubmit={login} style={{ height: '50rem', width: '40rem', marginLeft: '40rem' }}>
                            <h1 style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                <span></span>
                            </h1>
                            <h3 style={{ textAlign: 'center' }}>Log In</h3>

                            <div className="form-group">
                                <label for="email">Email address</label>
                                <input value={email} type="email" id="email" className="form-control" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} required />
                            </div>

                            <div className="form-group" style={{ position: 'relative' }}>
                                <label for="password">Password</label>
                                <input value={password} style={{ padding: '5px 30px 5px 10px' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter password" onChange={(event) => setPassword(event.target.value)} required />
                                <img
                                    style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                                    alt="Hide/Show password"
                                />
                                <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                            </div>
                            <button type="submit" className="btn btn-primary btn-block">Log In</button>
                            <p className="forgot-password text-right">
                                <a href="/forgot_password">Forgot password?</a>
                            </p>
                            <h5 style={{ textAlign: 'center' }}>New to Equify?</h5>
                            <a href="/register" className="btn btn-primary btn-block">Create your Equify account</a>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

// function login(e) {
//     e.preventDefault();
//     let request = {
//         email: document.getElementById('userEmail').value,
//         password: document.getElementById('userPassword').value
//     }
//     axios.post('http://localhost:4001/login', request)
//         .then(resp => {
//             alert(resp.data.message);
//         })
//         .catch(err => {
//             console.log(err);
//         })
// }

export default Login;
