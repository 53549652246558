import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from "./Account";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
import Select from 'react-select';

const Status = () => {
    const navigate = useNavigate();

    const [status, setStatus] = useState(false);

    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
        getSession()
            .then(session => {
                console.log("Session: ", session)
                setStatus(true);
            })
    }, []);

    const options = [
        { value: 'dashboard', label: 'Dashboard' },
        { value: 'profile', label: 'User Profile' },
        { value: 'logout', label: 'Logout' }
      ];

    const onChange = (e) => {
        const selectedVal = e.value;
        if (selectedVal === 'logout') {
            logout();
            navigate('/login');
        } else {
            navigate(`/${selectedVal}`);
        }
    }

    const logoutHandler = (e) => {
        e.preventDefault();

        logout()

        // setTimeout(function () {
        //     navigate('/');
        // }, 100);
    };

    return (
        <div>
            {status ?
                (
                    <div style={{ marginTop: '10px' }}>
                        <Select options={options} onChange={onChange} placeholder='Account'/>
                    </div>
                    
                    // <Link to='/login'>
                    //     <button onClick={logout} className='btn btn-custom' style={{ marginTop: '3px' }}><UserProfile style={{ width: '20px' }} fill="white" /></button>
                    // </Link>
                ) :
                (
                    <Link to='/login'>
                        <button className='btn btn-custom' style={{ marginTop: '3px' }}>Login</button>
                    </Link>
                )
            }
        </div>
    )
};
export default Status;