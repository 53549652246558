import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo2.jpg';
import { Navigation } from "./navigation";
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';

function ConfirmRegistration() {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resendCodeErrorMessage, setResendCodeErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [resendCodeSuccessMessage, setResendCodeSuccessMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    const confirm = (e) => {
        e.preventDefault();
        const userData = {
            Username: location.state.email,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);

        setResendCodeErrorMessage('');
        cognitoUser.confirmRegistration(code, true, (err, result) => {
            if (err) {
                console.log('error', err.message);
                setErrorMessage(err.message);
                return;
            }
            console.log('call result: ' + JSON.stringify(result));
            setErrorMessage('');
            setSuccessMessage("Success! Navigating to Login");
            setTimeout(function(){
                navigate('/login');
           }, 1000);
        });
    };

    const resendVerification = (e) => {
        e.preventDefault();
        const userData = {
            Username: location.state.email,
            Pool: UserPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                console.log('error', err.message);
                setResendCodeErrorMessage(err.message);
                return;
            }
            console.log('call result: ' + JSON.stringify(result));
            setResendCodeErrorMessage('');
            setResendCodeSuccessMessage("Verification Code Resent!");
        });
    };

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <form onSubmit={confirm} style={{ height: '50rem', width: '40rem', marginLeft: '40rem' }}>
                            <h1 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                <span></span>
                            </h1>
                            <h3 style={{ textAlign: 'center' }}>Confirm Email to Validate Account</h3>
                            <h4 style={{ textAlign: 'center' }}>A verification code has been sent to your email</h4>
                            <br></br>

                            <div className="form-group">
                                <label for="code">Verification Code</label>
                                <input value={code} type="text" id="code" className="form-control" placeholder="Enter verification code" onChange={(event) => setCode(event.target.value)} required />
                                <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                <div style={{ float: 'left', color: 'green', fontSize: '20px' }}>{successMessage}</div>
                            </div>

                            <button style={{marginTop: '4%'}} type="submit" className="btn btn-primary btn-block">Confirm</button>
                            <p style={{paddingTop: '3%'}}>Didn't receive a code? <button onClick={resendVerification} className="btn btn-primary">Resend Code</button></p>
                            <div style={{ textAlign: 'center', color: 'red', fontSize: '20px' }}>{resendCodeErrorMessage}</div>
                            <div style={{ textAlign: 'center', color: 'green', fontSize: '20px' }}>{resendCodeSuccessMessage}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConfirmRegistration;
