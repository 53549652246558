import React, { useState } from 'react';
import logo from '../images/logo2.jpg';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import { Navigation } from "./navigation";
import UserPool from '../UserPool';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

function Register() {
    const [errorMessage, setErrorMessage] = useState('');
    const [passConfirmed, setPassConfirmed] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConfirmRevealPwd, setIsConfirmRevealPwd] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const signUp = (e) => {
        e.preventDefault();
        let name = new CognitoUserAttribute({ Name: 'name', Value: firstName });
        let familyName = new CognitoUserAttribute({ Name: 'family_name', Value: lastName });

        UserPool.signUp(email, password, [name, familyName], null, (err, data) => {
            if (err) {
                console.log(err);
                setErrorMessage(err.message);
            }
            console.log(data);
            console.log(data.user.username);
            setErrorMessage('');
            navigate('/confirm', { state: { email: data.user.username } });
        });
    };

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <form onSubmit={signUp} style={{ height: '50rem', width: '40rem', marginLeft: '40rem' }}>
                            <h1 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                <span></span>
                            </h1>
                            <h3 style={{ textAlign: 'center' }}>Register</h3>
                            <p style={{ fontWeight: 'bold' }}>Create an account to begin your Equify journey</p>
                            <br></br>

                            <div className="form-group">
                                <label for="firstName">First Name</label>
                                <input value={firstName} type="text" id="firstName" className="form-control" placeholder="Enter first name" onChange={(event) => setFirstName(event.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label for="lastName">Last Name</label>
                                <input value={lastName} type="text" id="lastName" className="form-control" placeholder="Enter last name" onChange={(event) => setLastName(event.target.value)} required />
                            </div>

                            <div className="form-group">
                                <label for="email">Email address</label>
                                <input value={email} type="email" id="email" className="form-control" placeholder="Enter email" onChange={(event) => setEmail(event.target.value)} required />
                            </div>

                            <div className="form-group" style={{ position: 'relative' }}>
                                <label for="password">Password</label>
                                <input value={password} style={{ padding: '5px 30px 5px 10px' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter password" onChange={(event) => setPassword(event.target.value)} required />
                                <img
                                    style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                                    alt="Hide/Show password"
                                />
                            </div>

                            <div className="form-group" style={{ marginBottom: '3rem', position: 'relative' }}>
                                <label for="confirmPassword">Confirm Password</label>
                                <input value={confirmPassword} style={{ padding: '5px 30px 5px 10px' }} type={isConfirmRevealPwd ? "text" : "password"} id="confirmPassword" className="form-control" placeholder="Re-enter password" onChange={(event) => setConfirmPassword(event.target.value)} required />
                                <img
                                    style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                    title={isConfirmRevealPwd ? "Hide password" : "Show password"}
                                    src={isConfirmRevealPwd ? hidePwdImg : showPwdImg}
                                    onClick={() => setIsConfirmRevealPwd(prevState => !prevState)}
                                    alt="Hide/Show password"
                                />
                                <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                    minLength={8}
                                    value={password}
                                    valueAgain={confirmPassword}
                                    onChange={(isValid) => setPassConfirmed(isValid)}
                                />
                                <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                            </div>

                            <button type="submit" disabled={!passConfirmed} className="btn btn-primary btn-block">Create Account</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
