import React from 'react'
import { Container } from 'react-bootstrap'

const Author = ({ author }) => {
  return (
    <div className="author text-center">
      <img
        src={author.photo.url}
        alt={author.name}
        className='author-image'
      />
      <div className='author-text'>
        <h3 className='whiteText'>Author {author.name}</h3>
        <p>{author.bio}</p>
      </div>
    </div>

  )
}

export default Author