import React from 'react'
import { Link } from "react-router-dom";

import { useQuery, gql } from "@apollo/client";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './blog.css'

const categoriesQuery = gql`
query GetCategories {
    categories {
      name
      slug
    }
}
`;

const Categories = () => {
    const { data, loading, error } = useQuery(categoriesQuery);

    console.log("Categories")
    console.log(data)

    return (
        <div className="widget">
            <h3 className='whiteText'><b>Categories</b></h3>

            {data && data.categories.map((category) => (
                <div key={category.slug} className="widget-category">
                    <Link to={`/blog/category/${category.slug}`} className='link'>{category.name}</Link>
                </div>
            ))}


        </div>
    )
}

export default Categories