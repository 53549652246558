import React from 'react'

import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import moment from 'moment';
import Button from 'react-bootstrap/Button';

const PostDetail = ({ post }) => {
    const getContentFragment = (index, text, obj, type) => {
        let modifiedText = text;

        if (obj) {
            if (obj.bold) {
                modifiedText = (<b key={index}>{modifiedText}</b>);
            }

            if (obj.italic) {
                modifiedText = (<em key={index}>{modifiedText}</em>);
            }

            if (obj.underline) {
                modifiedText = (<u key={index}>{modifiedText}</u>);
            }
        }

        switch (type) {
            case 'heading-three':
                return <h3 key={index} className="text-xl font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h3>;
            case 'paragraph':
                return <p key={index} className="mb-8">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</p>;
            case 'heading-four':
                return <h4 key={index} className="text-md font-semibold mb-4">{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h4>;
            case 'image':
                return (
                    <img
                        key={index}
                        alt={obj.title}
                        height={obj.height}
                        width={obj.width}
                        src={obj.src}
                    />
                );
            default:
                return modifiedText;
        }
    };

    console.log("In PostDetail")
    console.log(post)
    return (
        <div className='postcard'>
            <img
                src={post.featuredImage.url}
                alt={post.title}
                className='postcard-main-image'
            />
            <span>
                <img
                    src={post.author.photo.url}
                    alt={post.author.name}
                    className='postcard-author-image'
                />
                Author: {post.author.name}

                <svg xmlns="http://www.w3.org/2000/svg" className="calendar-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>

                {moment(post.createdAt).format('MMM DD, YYYY')}
            </span>

            {/* <div className='text-center'> */}
                <h1>
                    <b>{post.title}</b>
                </h1>
                {post.content.raw.children.map((typeObj, index) => {
                    const children = typeObj.children.map((item, itemindex) => getContentFragment(itemindex, item.text, item));

                    return getContentFragment(index, children, typeObj, typeObj.type);
                })}
            {/* </div> */}

        </div>
    )
}

export default PostDetail