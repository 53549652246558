import React from "react";
import { Navigation } from "./navigation";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export const HowItWorks = (props) => {
  return (
    <div>
      <Navigation />
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>How It Works</h2>
            <h3>
              Equify is an <strong>Investor</strong>, not a Lender
            </h3>
            <h4>Access your home equity while still living in your home.</h4>
            <p>
              We provide a smart new financing option for homeowners who hate
              taking on debt and want to access their home equity. We invest in
              your home and provide you cash immediately in exchange for a piece
              of the future worth of your house.
              <br></br>
              <p>
                You can settle anytime within the next 5 years. Your peace of
                mind is our first priority.
              </p>
            </p>
          </div>
        </div>
      </div>
      <div id="testimonials" className="text-center">
        <div className="container">
          <div className="section-title text-center">
            <h2>Process</h2>
          </div>
          <div className="row">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#235F97",
                    color: "#fff"
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  #235F97"
                  }}
                  iconStyle={{
                    background: "#235F97",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"1"}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ color: "#fff" }}
                  >
                    Check if your home qualifies and get an estimate.
                  </h3>

                  <br></br>
                  <h5
                    className="vertical-timeline-element-subtitle"
                    style={{ color: "#fff" }}
                  >
                    To obtain a fast pre-approval and learn how much you could
                    get, just answer a few questions here. This takes less than
                    a minute, is completely free, and has no impact on your
                    credit score.
                  </h5>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: "#fff",
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"2"}
                >
                  <h3 className="vertical-timeline-element-title">
                    Have a call with one of our home equity experts and have all
                    your questions answered.
                  </h3>
                  <br></br>
                  <h5 className="vertical-timeline-element-subtitle">
                    We take customer education seriously—we want everyone we
                    partner with to understand how equity sharing works. This is
                    your opportunity to ask us anything!
                  </h5>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{
                    background: "#235F97",
                    color: "#fff"
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  #235F97"
                  }}
                  iconStyle={{
                    background: "#235F97",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"3"}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ color: "#fff" }}
                  >
                    Fill out an online application and upload required
                    documents. We are here to guide you every step of the way.
                  </h3>
                  <br></br>
                  <h5
                    className="vertical-timeline-element-subtitle"
                    style={{ color: "#fff" }}
                  >
                    You'll be familiar with this step if you've ever applied for
                    a mortgage, though our clients say ours is simpler. The
                    details you submit here will help us better understand your
                    position and potentially improve our offer.
                  </h5>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: "#fff",
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"4"}
                >
                  <h3 className="vertical-timeline-element-title">
                    Schedule a home visit with an independent, third-party
                    appraiser to set your initial home value.
                  </h3>
                  <br></br>
                  <h5 className="vertical-timeline-element-subtitle">
                    If we decide to invest in your property, we'll need to
                    figure out what your home's initial value is. We collaborate with non-affiliated
                    third-party appraisers to guarantee that the process is
                    fair. We'll finalise our offer to you after the appraisal is
                    completed.
                  </h5>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--education"
                  contentStyle={{
                    background: "#235F97",
                    color: "#fff"
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  #235F97"
                  }}
                  iconStyle={{
                    background: "#235F97",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"5"}
                >
                  <h3
                    className="vertical-timeline-element-title"
                    style={{ color: "#fff" }}
                  >
                    Receive your funds.
                  </h3>
                  <br></br>
                  <h5
                    className="vertical-timeline-element-subtitle"
                    style={{ color: "#fff" }}
                  >
                    We'll schedule a notary visit/zoom call to sign closing
                    documents and then electronically transfer the money to your
                    account. Your funds will be debited for a 3 percent
                    processing fee, an appraisal cost, and an escrow fee. Your
                    lawyer's office will receive a copy of the transaction.
                  </h5>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  iconStyle={{
                    background: "#fff",
                    color: "#000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px"
                  }}
                  icon={"6"}
                >
                  <h3 className="vertical-timeline-element-title">
                    Buy back your equity anytime within 5 years using any of
                    the following ways.
                  </h3>
                  <br></br>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Interested in an Equify Home Equity Investment?</h2>
            <p>See if you qualify in minutes</p>
          </div>
          <div className="row">
            <div
              id="comparison"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <a
                type="submit"
                href="/estimate"
                className="btn btn-custom btn-lg"
              >
                Get an Estimate
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
