import React, { useState, useContext } from 'react';
import { Navigation } from "./navigation";
import PasswordChecklist from "react-password-checklist";
import { Card } from 'react-bootstrap';
import { AccountContext } from "./Account";
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';

function Settings() {
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [passConfirmed, setPassConfirmed] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealConfirmNewPwd, setIsRevealConfirmNewPwd] = useState(false);
    const [changePasswordError, setChangePasswordError] = useState('');
    const [changePasswordSuccessMessage, setChangePasswordSuccessMessage] = useState('');

    const { getSession } = useContext(AccountContext);

    const changePassword = event => {
        event.preventDefault();

        getSession().then(({ user }) => {
            user.changePassword(password, newPassword, (err, results) => {
                if (err) {
                    console.log(err);
                    setChangePasswordSuccessMessage('');
                    setChangePasswordError(err.message);
                } else {
                    console.log(results);
                    setChangePasswordError('');
                    setChangePasswordSuccessMessage('Successfully update password');
                }
            });
        });
    };

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                    <a href="/profile" style={{ width: '15rem' }} className="btn btn-primary btn-block">Back to User Profile</a>
                        <Card style={{ width: '18rem', marginLeft: '40rem' }}>
                            <Card.Body>
                                <h3>Change Password</h3>
                                <form onSubmit={changePassword} style={{ height: '50rem', width: '40rem' }}>
                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label>Current Password</label>
                                        <input value={password} style={{ padding: '5px 30px 5px 10px' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter current password" onChange={event => setPassword(event.target.value)} />
                                        <img
                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                            src={isRevealPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                            alt="Hide/Show password"
                                        />
                                    </div>

                                    <div className="form-group" style={{ position: 'relative' }}>
                                        <label>New Password</label>
                                        <input value={newPassword} style={{ padding: '5px 30px 5px 10px' }} type={isRevealNewPwd ? "text" : "password"} id="newpassword" className="form-control" placeholder="Enter new password" onChange={event => setNewPassword(event.target.value)} />
                                        <img
                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                            title={isRevealNewPwd ? "Hide password" : "Show password"}
                                            src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                            alt="Hide/Show password"
                                        />
                                    </div>

                                    <div className="form-group" style={{ marginBottom: '3rem', position: 'relative' }}>
                                        <label>Confirm New Password</label>
                                        <input value={confirmNewPassword} style={{ padding: '5px 30px 5px 10px' }} type={isRevealConfirmNewPwd ? "text" : "password"} id="confirmnewpassword" className="form-control" placeholder="Confirm new password" onChange={event => setConfirmNewPassword(event.target.value)} />
                                        <img
                                            style={{ width: '20px', position: 'absolute', right: '1rem', top: '3rem' }}
                                            title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                                            src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg}
                                            onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
                                            alt="Hide/Show password"
                                        />
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                            minLength={8}
                                            value={newPassword}
                                            valueAgain={confirmNewPassword}
                                            onChange={(isValid) => setPassConfirmed(isValid)}
                                        />
                                        <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{changePasswordError}</div>
                                        <div style={{ float: 'left', color: 'green', fontSize: '20px' }}>{changePasswordSuccessMessage}</div>
                                    </div>

                                    <button type="submit" disabled={!passConfirmed} style={{ marginTop: '4%' }} className="btn btn-primary btn-block">Change password</button>
                                </form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
