import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Video } from "./video";
import { Services } from "./services";
import { Contact } from "./contact";
import { NextSteps } from "./nextSteps";
import JsonData from "../data/data.json";
import SmoothScroll from "smooth-scroll";

import "../App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
        <Navigation />
        <Header data={landingPageData.Header} />
        <Video data={landingPageData.Features} />
        <Services data={landingPageData.Services} />
        {/* <About data={landingPageData.About} /> */}
        {/* <Gallery data={landingPageData.Gallery} /> */}
        {/*<Testimonials data={landingPageData.Testimonials} />*/}
        <NextSteps data={landingPageData.NextSteps} />
        <Contact data={landingPageData.Contact} />

    </div>
  );
};

export default Home;
