import React from 'react';
import { Navigation } from "./navigation";
import { Card } from 'react-bootstrap';

function Dashboard() {

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row text-center'>
                    <a href="/profile" style={{ width: '15rem' }} className="btn btn-primary btn-block">Back to User Profile</a>
                    {/* <h1>Dashboard</h1> */}
                    <h1>COMING SOON</h1>
                    <h4 style={{ marginTop: '5%' }}>Please join the waitlist if you haven't done so already!</h4>
                    <div className='col-md-3'></div>
                    <div className='col-md-6 text-center' style={{ marginTop: '2%' }}>
                        <a href="/join_waitlist" className='btn btn-custom btn-lg page-scroll'>Join the Waitlist</a>{' '}
                    </div>
                    {/* <div className='col-md-4 text-center'>
                        <Card border="secondary" style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="holder.js/100px180" />
                            <Card.Body>
                                <Card.Title>Create New Application</Card.Title>
                                <Card.Text>
                                    Create a new Equify home equity investment application in minutes
                                </Card.Text>
                                <div className='row text-center'>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-9'>
                                    <a href="/application"  className="btn btn-primary btn-block">New Application</a>
                                    </div>
                                    
                                    <div className='col-md-1'></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className='col-md-4 text-center'>
                        <Card border="secondary" style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="holder.js/100px180" />
                            <Card.Body>
                                <Card.Title>View Documents</Card.Title>
                                <Card.Text>
                                    View your current Equify documents
                                </Card.Text>
                                <div className='row text-center'>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-9'>
                                    <a href="/documents" className="btn btn-primary btn-block">View Documents</a>
                                    </div>
                                    
                                    <div className='col-md-1'></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div> */}
                    <div className='col-md-3'></div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
