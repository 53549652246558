import { useState, useEffect } from "react";
import { Navigation } from "./navigation"
import { About } from "./about"
// import { Team } from "./Team"
import JsonData from "../data/data.json";
import "./css/style.css"

export const AboutTeam = (props) => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    return (
        <div>
            <Navigation />
            <div id='portfolio' className='text-center'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>About Us</h2>
                        <h3>We are a team of fintech engineers and mortgage brokers, <br></br>with decades of experience in the real estate industry and <br></br>many years of experience in technology.</h3>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='text-center'>
                                <About data={landingPageData.About} />
                                {/* <Team data={landingPageData.Team} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>

            </div>
        </div>
    )
}

