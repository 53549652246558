import React from 'react'
import { useParams } from 'react-router-dom';
import { Navigation } from "../navigation";

import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { PostDetail, Categories, PostDetailWidget, Author, Comments, CommentsForm } from ".";

import "../../App.css";
import './blog.css'

const postQuery = gql`
query GetPostDetails($slug: String!) {
    post(where: {slug: $slug}) {
      author {
        bio
        name
        id
        photo {
          url
        }
      }
      createdAt
      slug
      title
      excerpt
      featuredImage {
        url
      }
      categories {
        name
        slug
      }
      content {
        raw
      }
    }
  }
`;

export const Post = () => {
    // const { data, loading, error } = useQuery( postQuery, { variables: {"slug": "unlock-your-home-equity"} });
    const { data, loading, error } = useQuery(postQuery, {
        variables: {
            slug: useParams().slug,
        },
    });
    console.log("In Post")
    console.log("Slug: " + useParams().slug)
    console.log(data)

    const slug = useParams().slug

    return (
        <Container>
            <Navigation />
            {data && data.post &&
                <div id='portfolio'>
                    <Row>

                        <div className='text-center section-title'>
                            <h2>{data.post.title}</h2>
                        </div>
                    </Row>

                    <Row>
                        <Col lg={9}>
                            <PostDetail post={data.post} />
                            <Author author={data.post.author} />
                            <CommentsForm slug={data.post.slug} />
                            <Comments slug={data.post.slug} />
                        </Col>
                        <Col lg={3}>
                            <PostDetailWidget slug={data.post.slug} categories={data.post.categories.map((category) => category.slug)} />
                            <Categories />
                        </Col>
                    </Row>

                </div>
            }
            {data && data.post == null &&
              <div id='portfolio'>
                    <Row>

                        <div className='text-center section-title'>
                            <h2>{slug} is an invalid article link</h2>
                        </div>
                    </Row>
                </div>

            }

            
        </Container>
    )
}

export default Post
