import React, { useState, useRef } from 'react';
import { Card, ProgressBar, ListGroup, ListGroupItem } from 'react-bootstrap';
import { RangeStepInput } from 'react-range-step-input';
import { Navigation } from "./navigation";
import CurrencyInput from 'react-currency-input-field';
import { useNavigate } from "react-router-dom";
import "./css/style.css";
import { useDebouncedCallback } from 'use-debounce';

function currencyFormat(num) {
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function RiskAdjustedValue({ num }) {
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Risk-Adjusted Home Value</h3>
        <h4 className="mb-2 text-muted">{currencyFormat(num)}</h4>
        <p>
          Because Equify shares in downside, Equify applies a Risk Adjustment of 5-10% to
          offset small declines in home value and protect its initial investment.
          In this example, we will be risk adjusting the home value by 5%.
        </p>
      </Card.Body>
    </Card>
  );
}

function InvestValue({ num }) {
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Equify Invests Today</h3>
        <h4 className="mb-2 text-muted">{currencyFormat(num)}</h4>
        <p>
          This estimate is for illustrative purposes only and
          assumes a well-qualified homeowner with 30% equity in home.
        </p>
      </Card.Body>
    </Card>
  );
}

function TermSlider({ slider, termLength }) {
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Time to sale or buy back</h3>
        <p>
          Equify is repaid at the end of the term, when the home is sold,
          or when the homeowner chooses to buy us out.
        </p>
        {slider}
        {termLength + " year(s)"}
        <br></br>
        <small>« Drag Slider »</small>
      </Card.Body>
    </Card>
  );
}

function ShareOfAppreciation() {
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Equify's Share of Appreciation</h3>
        <h4 className="mb-2 text-muted">
          <ProgressBar style={{ margin: '1rem' }}>
            <ProgressBar style={{ backgroundColor: '#235F97', height: '10rem' }} now={25} label={`${25}%`} key={1} />
            <ProgressBar style={{ backgroundColor: '#d4d4d4', color: 'black', height: '10rem' }} now={75} label={`${75}% to Homeowner`} key={2} />
          </ProgressBar>
        </h4>
        <p>
          In exchange for Equify's investment today, Equify receives a share in the
          home’s appreciation above the Risk-Adjusted Home Value.
          The share is determined during underwriting.
        </p>
      </Card.Body>
    </Card>
  );
}

function AvgAppr({ num, investment, termLength }) {
  let finalHomeValue = (num * Math.pow(1.035, termLength))
  let appr = ((num * Math.pow(1.035, termLength)) - (num * 0.95))
  let rev = (appr * 0.25)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * 0.95)) * 0.25
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let subtitle = <h4>(3.5% / year)</h4>
  if (capped < rev) {
    subtitle = <h4>(3.5% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the amount you’ll pay Equify is capped,
      so you keep more of the upside.</span></h4>
  }
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Average Appreciation</h3>
        {subtitle}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function NoAppr({ num, investment, termLength }) {
  let finalHomeValue = (num)
  let appr = ((num) - (num * 0.95))
  let rev = (appr * 0.25)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * 0.95)) * 0.25
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>No Appreciation</h3>
        <h4>(0% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the only cost you’ll pay Equify is the risk adjustment initially made.</span></h4>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function LargeDeline({ num, investment, termLength }) {
  let finalHomeValue = (num * Math.pow(0.95, termLength))
  let appr = ((num * Math.pow(0.95, termLength)) - (num * 0.95))
  let rev = (appr * 0.25)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * 0.95)) * 0.25
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let totalCost = equifyShare - investment
  if (totalCost < 0) {
    totalCost = 0
  }
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>Large Decline</h3>
        <h4>(-5% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, you’ll repay Equify less than Equify
          invested in your home because we share in losses.</span></h4>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(totalCost)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function HighAppr({ num, investment, termLength }) {
  let finalHomeValue = (num * Math.pow(1.08, termLength))
  let appr = ((num * Math.pow(1.08, termLength)) - (num * 0.95))
  let rev = (appr * 0.25)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * 0.95)) * 0.25
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let subtitle = <h4>(8% / year)</h4>
  if (capped < rev) {
    subtitle = <h4>(8% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the amount you’ll pay Equify is capped,
      so you keep more of the upside.</span></h4>
  }
  return (
    <Card style={cardStyle1}>
      <Card.Body>
        <h3>High Appreciation</h3>
        {subtitle}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function Pricing() {
  const nameForm = useRef(null);
  const [state, setState] = useState(500000);
  const navigate = useNavigate();
  const [riskAdjustVal, setRiskAdjustedVal] = useState(500000 * 0.95)
  const [investVal, setInvestVal] = useState(500000 * 0.30 * 0.25)
  const [homeStartingValue, setHomeStartingValue] = useState(500000)
  const [timePeriod, setTimePeriod] = useState(3)

  const debounced = useDebouncedCallback(function (value) {
    console.log('debounding');
    setState(value);
    const form = nameForm.current;
    let inputValue = parseFloat(`${form['homevalue'].value}`.replace(/[$,]+/g, ""));
    setRiskAdjustedVal(inputValue * 0.95);
    setInvestVal(inputValue * 0.30 * 0.25);
    setHomeStartingValue(inputValue);
  }, 500);

  const onSliderChange = (e) => {
    const newVal = e.target.value;
    setTimePeriod(newVal);
  }

  const handleClick = () => {
    const form = nameForm.current
    let data = {
      homeValue: parseFloat(`${form['homevalue'].value}`.replace(/[$,]+/g, ""))
    };
    navigate('/estimate', { state: data });
  }

  return (
    <div>
      <Navigation />
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>See how much you could get today</h2>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
              <form ref={nameForm}>
                <h3>Home Value</h3>
                <CurrencyInput
                  id="homevalue-input"
                  name={'homevalue'}
                  defaultValue={500000}
                  decimalsLimit={2}
                  onValueChange={(value) => debounced(value)}
                  prefix='$'
                  allowNegativeValue={false}
                  className="form-control form-control-lg"
                  style={{ marginRight: '1rem', textAlign: 'center', fontSize: '25px', color: 'black', width: '20rem' }}
                />
              </form>
            </div>
          </div>
          <div className='row'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <RiskAdjustedValue num={riskAdjustVal} />
              <InvestValue num={investVal} />
            </div>
          </div>
        </div>
      </div>
      <div id='testimonials' className='text-center' style={{ paddingBottom: '10rem' }}>
        <div className='container'>
          <div className='section-title text-center'>
            <h2>How much could Equify cost?</h2>
          </div>
          <div className='row'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <TermSlider slider={<RangeStepInput
                min={1} max={5}
                value={timePeriod} step={1}
                onChange={onSliderChange}
              />
              } termLength={timePeriod} />
              <ShareOfAppreciation />
            </div>
          </div>
          <div className='row'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <AvgAppr num={homeStartingValue} investment={investVal} termLength={timePeriod} />
              <NoAppr num={homeStartingValue} investment={investVal} termLength={timePeriod} />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <LargeDeline num={homeStartingValue} investment={investVal} termLength={timePeriod} />
              <HighAppr num={homeStartingValue} investment={investVal} termLength={timePeriod} />
            </div>
          </div>
        </div>
      </div>
      <div id='portfolio' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Key Takeaways</h2>
          </div>
          <div className='row'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>What if my home loses value?</h3>
                  <p>
                    If your home depreciates below the Risk-Adjusted Home Value, we share in the downside.
                  </p>
                </Card.Body>
              </Card>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>What’s the most I’ll repay Equify?</h3>
                  <p>
                    Equify is designed to be fair and equitable. If the home appreciates above the Risk-Adjusted Home Value,
                    we share in the upside but only up to a house appreciation of <strong>7% year over year</strong>. The rest is a bonus for you.
                  </p>
                </Card.Body>
              </Card>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>Are there monthly payments?</h3>
                  <p>
                    No, a Home Equity Investment doesn’t come with a monthly payment.
                    Instead, you can either pay back in one lump-sum at the end of the term, or
                    make payments throughout the term that is flexible to you.
                    We’re invested in the property with you, so we do well when you do well.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>When do I repay Equify?</h3>
                  <p>
                    You can buy back Equify's share at any time prior to the end of the 1-5 year term.
                    The amount you must pay to buy back Equify share will be calculated using the
                    property’s value at the time.
                  </p>
                </Card.Body>
              </Card>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>Does Equify share ownership of my home?</h3>
                  <p>
                    Equify is not added to the title of the property.
                    You continue to own and live in the property and you decide when to sell your house.
                    You stay in control.
                  </p>
                </Card.Body>
              </Card>
              <Card style={cardStyle2}>
                <Card.Body>
                  <h3>What terms will Equify offer me?</h3>
                  <p>
                    These terms may not be representative of what you are offered.
                    To determine your offer, our team will consider your home equity,
                    credit profile, and the market’s expectations, amongst many other factors.
                  </p>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div id='services' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Interested in an Equify Home Equity Investment?</h2>
            <p>See if you qualify in minutes</p>
          </div>
          <div className='row'>
            <div style={{ alignItems: 'center', justifyContent: 'center', }}>
              <div id='comparison' style={{ alignItems: 'center', justifyContent: 'center', }}>
                <button type='submit' className='btn btn-custom btn-lg page-scroll' style={{ marginTop: '1.5rem' }} onClick={handleClick}>Get an Estimate</button>
                {/* <a type='submit' href="/estimate" className='btn btn-custom btn-lg' onSubmit={handleSubmit}>Get an Estimate</a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const cardStyle1 = {
  flex: '0 0 calc(50.00% - 20px)',
  maxWidth: 'calc(50.00% - 20px)',
  width: '100%',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '20px',
  marginBottom: '20px',
  borderRadius: '20px'
};

const cardStyle2 = {
  flex: '0 0 calc(33.33% - 20px)',
  maxWidth: 'calc(33.33% - 20px)',
  width: '100%',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '20px',
  marginBottom: '20px',
  borderRadius: '20px'
};

export default Pricing;
