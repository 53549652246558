import "./css/style.css";

export const NextSteps = (props) => {
    return (
      <div id='team' className='text-center'>
        <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
            <h2>Complete your Goals!</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
          <div id='row'>
            {props.data
              ? props.data.map((d, i) => (
                  <div className='col-md-4 col-sm-6 team' id="next-steps">
                    <div className='thumbnail'>
                      {' '}
                      
                      <img src={d.img} alt='...' className='team-img' />
                      <div className='caption'>
                        <h4>{d.title}</h4>
                        <p>{d.description}</p>
                      </div>
                    </div>
                    
                  </div>
                ))
              : 'loading'}
          </div>
        </div>
      </div>
    )
  }
  