import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/logo2.jpg';
import { Navigation } from "./navigation";
import UserPool from '../UserPool';
import { CognitoUser } from 'amazon-cognito-identity-js';
import showPwdImg from '../images/show-password.svg';
import hidePwdImg from '../images/hide-password.svg';
import PasswordChecklist from "react-password-checklist";

function ForgotPassword() {
    const [code, setCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [resetPasswordSuccessMessage, setResetPasswordSuccessMessage] = useState('');
    const [email, setEmail] = useState('');
    const [stage, setStage] = useState(1);  // 1 = email stage, 2 = code stage
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConfirmRevealPwd, setIsConfirmRevealPwd] = useState(false);
    const [passConfirmed, setPassConfirmed] = useState(false);
    const navigate = useNavigate();

    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool: UserPool
        });
    };

    const sendCode = event => {
        event.preventDefault();

        getUser().forgotPassword({
            onSuccess: data => {
                console.log("onSuccess:", data);
                setErrorMessage('');
            },
            onFailure: err => {
                console.error("onFailure:", err);
                setErrorMessage(err.message);
            },
            inputVerificationCode: data => {
                console.log("Input code:", data);
                setStage(2);
            }
        });
    };

    const resetPassword = event => {
        event.preventDefault();
        getUser().confirmPassword(code, password, {
            onSuccess: data => {
                setResetPasswordError('');
                console.log("onSuccess:", data);
                setResetPasswordSuccessMessage('Success! Navigating to Login.');
                setTimeout(function () {
                    navigate('/login');
                }, 1000);
            },
            onFailure: err => {
                console.error("onFailure:", err);
                setResetPasswordError(err.message);
            }
        });
    };

    return (
        <div>
            <Navigation />
            {stage === 1 && (
                <div className='container' style={{ paddingTop: '100px' }}>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <form onSubmit={sendCode} style={{ height: '50rem', width: '40rem', marginLeft: '40rem' }}>
                                <h1 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                    <span></span>
                                </h1>
                                <h3 style={{ textAlign: 'center' }}>Forgot Password</h3>
                                <br></br>

                                <div className="form-group">
                                    <label for="email">Email address</label>
                                    <input value={email} type="email" id="email" className="form-control" placeholder="Enter email" onChange={event => setEmail(event.target.value)} required />
                                    <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                </div>
                                <button type="submit" style={{ marginTop: '4%' }} className="btn btn-primary btn-block">Send verification code</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {stage === 2 && (
                <div className='container' style={{ paddingTop: '100px' }}>
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <form onSubmit={resetPassword} style={{ height: '50rem', width: '40rem', marginLeft: '40rem' }}>
                                <h1 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                    <span></span>
                                </h1>
                                <h3 style={{ textAlign: 'center' }}>Reset Password</h3>
                                <h4 style={{ textAlign: 'center' }}>Verification code sent to {email}</h4>
                                <br></br>

                                <div className="form-group">
                                    <input value={code} type="text" id="code" className="form-control" placeholder="Enter verification code" onChange={event => setCode(event.target.value)} />
                                </div>

                                <div className="form-group" style={{ position: 'relative' }}>
                                    <input value={password} style={{ padding: '5px 30px 5px 10px' }} type={isRevealPwd ? "text" : "password"} id="password" className="form-control" placeholder="Enter new password" onChange={event => setPassword(event.target.value)} />
                                    <img
                                        style={{ width: '20px', position: 'absolute', right: '1rem', top: '0.75rem' }}
                                        title={isRevealPwd ? "Hide password" : "Show password"}
                                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        alt="Hide/Show Password"
                                    />
                                </div>

                                <div className="form-group" style={{ marginBottom: '3rem', position: 'relative' }}>
                                    <input value={confirmPassword} style={{ padding: '5px 30px 5px 10px' }} type={isConfirmRevealPwd ? "text" : "password"} id="confirmpassword" className="form-control" placeholder="Confirm new password" onChange={event => setConfirmPassword(event.target.value)} />
                                    <img
                                        style={{ width: '20px', position: 'absolute', right: '1rem', top: '0.75rem' }}
                                        title={isConfirmRevealPwd ? "Hide password" : "Show password"}
                                        src={isConfirmRevealPwd ? hidePwdImg : showPwdImg}
                                        onClick={() => setIsConfirmRevealPwd(prevState => !prevState)}
                                        alt="Hide/Show Password"
                                    />
                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        onChange={(isValid) => setPassConfirmed(isValid)}
                                    />
                                    <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{resetPasswordError}</div>
                                    <div style={{ float: 'left', color: 'green', fontSize: '20px' }}>{resetPasswordSuccessMessage}</div>
                                </div>

                                <button type="submit" disabled={!passConfirmed} style={{ marginTop: '4%' }} className="btn btn-primary btn-block">Change password</button>
                            </form>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ForgotPassword;
