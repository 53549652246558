import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Navigation } from "./navigation";

function Comparison() {
    return (
        <div>
            <Navigation />
            <div id='portfolio' className='text-center'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>An Equify Home Equity Investment <br></br> is an optimal way to <br></br> access your home equity</h2>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='service-desc'>
                                <Card style={{ height: '24rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '20px' }}>
                                    <Card.Body>
                                        <h3>No impact to credit score</h3>
                                        <p>
                                            A home equity investment is a way for homeowners to access their home equity.
                                            Customers can tap into their home equity for many reasons including: </p>
                                        <ul style={{ textAlign: 'left' }}>
                                            <li>1. Renovating or repairing their homes</li>
                                            <li>2. Paying off debts</li>
                                            <li>3. Purchase another property</li>
                                            <li>4. Diversify portfolio</li>
                                            <li>5. Fund your family or educations</li>
                                        </ul>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='service-desc'>
                                <Card style={{ height: '24rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '20px' }}>
                                    <Card.Body>
                                        <h3>No monthly payments or interest</h3>
                                        <p>
                                            You owe nothing for up to 5 years with no monthly payments.
                                            You also have the freedom to repay anytime within the 1-5 year period.
                                            Unlike second mortgages, our solution does not accrue interest as
                                            we are invested in the home with you, and we make money when you do.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='service-desc'>
                                <Card style={{ height: '24rem', border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: '20px' }}>
                                    <Card.Body>
                                        <h3>Market Slump Protection</h3>
                                        <p>
                                            Because Equify is a home equity investment company,
                                            we share in the appreciation but also in the downside in the house.
                                            Unlike second mortgage companies, we do not accrue interest
                                            through market slumps, and when this occurs
                                            you’ll repay less than Equify's investment because we share in losses.
                                        </p>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='testimonials' className='text-center'>
                <div className='container'>
                    <div className='section-title text-center'>
                        <h2>Side by side comparison</h2>
                        <h5><center>Take a look below to see why Equify may be the right option for you</center></h5>
                    </div>
                    <div className='row'>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Table bordered style={{ width: '60rem', textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black' }}></th>
                                        <th style={{ backgroundColor: '#235F97', color: 'white' }}>Equify</th>
                                        <th style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black' }}>Banks</th>
                                        <th style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black' }}>HELOC</th>
                                        <th style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', color: 'black' }}>Private Lenders</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ color: 'black' }}>Doesn’t impact credit score</td>
                                        <td style={{ backgroundColor: 'rgba(35, 95, 151, 0.55)', color: '#235F97' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td></td>
                                        <td></td>
                                        <td style={{ color: 'black' }}><FontAwesomeIcon icon={faCheck} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black' }}>No monthly payments required</td>
                                        <td style={{ backgroundColor: 'rgba(35, 95, 151, 0.55)', color: '#235F97' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black' }}>No accrued interest</td>
                                        <td style={{ backgroundColor: 'rgba(35, 95, 151, 0.55)', color: '#235F97' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black' }}>Easy to qualify</td>
                                        <td style={{ backgroundColor: 'rgba(35, 95, 151, 0.55)', color: '#235F97' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td></td>
                                        <td style={{ color: 'black' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td style={{ color: 'black' }}><FontAwesomeIcon icon={faCheck} /></td>
                                    </tr>
                                    <tr>
                                        <td style={{ color: 'black' }}>Market slump protection</td>
                                        <td style={{ backgroundColor: 'rgba(35, 95, 151, 0.55)', color: '#235F97' }}><FontAwesomeIcon icon={faCheck} /></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <div id='services' className='text-center'>
                <div className='container'>
                    <div className='section-title'>
                        <h2>Interested in an Equify Home Equity Investment?</h2>
                        <p>See if you qualify in minutes</p>
                    </div>
                    <div className='row'>
                        <div id='comparison' style={{ alignItems: 'center', justifyContent: 'center', }}>
                            <a type='submit' href="/estimate" className='btn btn-custom btn-lg'>Get an Estimate</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comparison;
