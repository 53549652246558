import { Navigation } from "./navigation"
import React from "react";
import FaqComp from "react-faq-component";
import "./css/style.css"

const data = {
    rows: [
        {
            title: "Who can use Equify?",
            content: `Since there are a lot of factors that go into determining if Equify can invest in a property, each property is evaluated independently. However,
            here are some of the qualities that usually make a good fit: 
            <br/>
            <br/>&emsp;Your property is located in a province in which we’re currently operational
            <br/>&emsp;You have a credit score above 660
            <br/>&emsp;You have a minimum of 30% equity in your home
            <br/>
            <br/>The investment amount you’re looking for is under $100,000. This is the maximum amount that we can invest, and it will depend on home value and equity percentage, as well.`,
        },
        {
            title: "How do homeowners qualify for an equity investment?",
            content: `Create an account with Equify and login. Then, follow the steps to create your profile and fill out the application process. Equify will try to get back to you within 1-3 business days. `,
        },
        {
            title: "How long does it usually take to be approved for Equify?",
            content: `This process varies from home to home, but can take up to 3-4 weeks. `,
        },
        {
            title: "How much money can I get from Equify?",
            content: `You can get up to $100,000 from Equify. `,
        },
        {
            title: "Can I repay Equify before the end of the term?",
            content: `You have the flexibility to buy Equify out at any time during your term. There's no lockout or prepayment penalty. Equify is with you for as long – or as short – as you'd like.`,
        },
        {
            title: "Can I remodel the home at any time?",
            content: `As your investment partner, Equify is an enthusiastic supporter of home improvements that add value to your home. You maintain full ownership and control over your home, so remodeling is your decision and only your decision. 

            `,
        },
        {
            title: "Is Equify added to the title of the property? ",
            content:
                "Equify is not added to the title of the property. You retain sole ownership of your home with Equify as the investor.",
        },
        {
            title: "How is my property's value determined?",
            content: `If you have gotten your home appraised in the last 3 months, we will use that value. Otherwise, there are two approaches.
            <br/>
            <br/>&emsp;1. Equify will send an appraiser to your property to get the value of the home.
            <br/>&emsp;2. Equify will use the PurView software to get the value of the home.
            <br/>
            <br/>With either method 1 or 2, we will consult the value of the home with you in order to come to an agreement for if this value is reasonable.
            <br/>Method 1 will cost between $300-$600 and method 2 will cost $100.
            `,
        },
        {
            title: "What if I want to refinance my home in the future?",
            content: `With Equify, you're free to refinance your property whenever you'd like. One of the most common exit strategies for Equify is a cash-out refinance. However, you don't have to pay back Equify if you decide to refinance your home without taking out additional cash. This process is known as a rate refinance.`,
        },
        {
            title: "Who is responsible for property taxes, insurance and property maintenance costs?",
            content: `You are responsible for property taxes, insurance, and maintenance, but Equify is a great solution for homeowners struggling with the cost of homeownership. You can use your investment funds to cover these – and other – expenses. `,
        },
        {
            title: "What happens if the homeowner dies?",
            content: `In the event of a homeowner's death, Equify’s contract continues with the co-owner or the estate. Death doesn't trigger an acceleration of the homeowner’s agreement with Equify and cause repayment to come due. The heir of the estate has the same term to pay Equify back as the original homeowner through a sale, refinance or another source of funds.  `,
        },
        {
            title: "How is Equify different from a reverse mortgage?",
            content: `Typically reverse mortgages require customers to be aged 55+ and charge interest on the loan. Equify will be available for all ages and will NOT charge interest.`,
        }

//         {
//             title: "What is Equify's tax treatment? What taxes do I pay?",
//             content: `Equify is built to be tax-friendly for homeowners. However, you should consult with your tax advisor before taking an investment from Equify. For many homeowners that Equify invests with, the following is true: 
// Equify (and Equify's investors) pay taxes on its share of any appreciation, which reduces your capital gains if you exit through a sale. 
// If you repay Equify at the end of the term without selling the property, there is no impact on the eventual capital gains on selling the home. There may still be an opportunity for other tax deductions.
// The initial Equify funds (also known as the Option Investment Payment) are a tax-deferred payment. 
// You may be subject to taxes when:
//  You sell your home
// You repurchase the obligation from Equify for an amount less than the Option Investment Payment 
// Equify cancels the Agreement, releasing you from any obligations
// Again, homeowners should consult with a tax advisor before taking a Equify investment. Equify disclaims any representation or warranty concerning tax treatment of the Equify Homeowner Agreement, whether generally, or as it applies to your unique situation.
//  `,
//         },
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "#1C5993",
    rowTitleTextSize: "25",
    rowTitleColor: "#215B90",
    rowContentColor: "#1C5993",
    rowContentPaddingTop: "0"
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
};

export const Faq = (props) => {
    return (
        <div>
            <Navigation />
            <div className='container' id='portfolio'>
                <div className='row'>
                    <div className='col-md-8 col-md-offset-2'>
                        <div className='section-title text-center'>
                            <h2>Frequently Asked Questions</h2>
                        </div>
                        <FaqComp
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
