import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from "react-router-dom";

import { useQuery, gql } from "@apollo/client";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './blog.css'


const relatedPostsQuery = gql`
query GetPostDetails ($slug: String!, $categories: [String!]){
    posts(
        where: { slug_not: $slug, AND: { categories_some: { slug_in: $categories }} }
        last: 3
    ) {
      title
      featuredImage {
          url
      }
      createdAt
      slug
    }
}
`;

const PostDetailWidget = ({ slug, categories}) => {
  const { data, loading, error } = useQuery(relatedPostsQuery, {
    variables: {
        "slug": slug,
        "categories": categories
    },
});


  console.log("In PostDetailWidget")
  console.log(data)

  return (

    <div className="widget">
            <h3 className='whiteText'><b>Related Posts</b></h3>

            {data && data.posts.map((post) => (
                <div key={post.title} className="widget-post">
                    <Row>

                        <Col sm={5}>
                            <img
                                src={post.featuredImage.url}
                                alt={post.title}
                                className='widget-image'
                            />
                        </Col>

                        <Col sm={7}>
                            <p className="widget-date">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
                            <Link to={`/blog/post/${post.slug}`} className='link'>
                                <p>{post.title}</p>
                            </Link>
                        </Col>
                    </Row>

                </div>
            ))}


        </div>
  )
}

export default PostDetailWidget