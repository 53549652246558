import React, { useState, useEffect } from 'react'
import moment from 'moment'
import parse from 'html-react-parser'

import { useQuery, gql } from "@apollo/client";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const getCommentsQuery = gql`
query GetComments($slug: String!) {
  comments(
    where: {post: { slug: $slug }}
    orderBy: createdAt_DESC
  ) {
    name
    createdAt
    comment
  }
}
`;

const Comments = ({ slug }) => {
  const [comments, setComments] = useState([])

  useEffect(() => {

  })

  const { data, loading, error } = useQuery(getCommentsQuery, {
    variables: {
      "slug": slug
    }
  });


  console.log("Comments Data")
  console.log(data)

  return (
    <div>

      {data && data.comments.length > 0 &&

        <div className="comments-display">
          <h3><b>{data.comments.length} Comments</b></h3>

          {data.comments.map((comment) => (
            <div key={comment.createdAt} className="comment-single">
              <p className="comment-font">
                <span>
                  <b>{comment.name}</b> on {moment(comment.createdAt).format('MMM DD, YYYY')}
                </span>
              </p>
              <p className="comment-font">{parse(comment.comment)}</p>
            </div>
            ))
          }
        </div>
      }

      {
        data == null || data.comments.length == 0 &&
        <div className="comments-display">
          <h3><b>0 Comments</b></h3>
        </div>

      }
    </div>
  )
}

export default Comments