import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import { useQuery, gql } from "@apollo/client";
import { GraphQLClient, gql} from "graphql-request"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import validator from "validator";

import '../blog.css'

const recentPostsQuery = gql`
query GetPostDetails {
    posts(
        orderBy: createdAt_DESC
        last: 3
    ) {
      title
      featuredImage {
          url
      }
      createdAt
      slug
    }
}
`;

const CommentsForm = ({ slug }) => {
  const [error, setError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [localStorage, setLocalStorage] = useState(null)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [formData, setFormData] = useState({ name: "", email: "", comment: "", storeData: true });

  useEffect(() => {
    setLocalStorage(window.localStorage);
    const initalFormData = {
      name: window.localStorage.getItem('name'),
      email: window.localStorage.getItem('email'),
      storeData: true
    };
    setFormData(initalFormData);
  }, []);

  const submitComment = (commentObj) => {

    const graphQLClient = new GraphQLClient(process.env.REACT_APP_GRAPHCMS_ENDPOINT, {
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_DEV_KEY}`
      }
    })

    const query = gql `
      mutation CreateComment($name: String!, $email: String!, $comment: String!, $slug: String!) {
        createComment(data: { name: $name, email: $email, comment: $comment, post: { connect: { slug: $slug } } }) { id } 
      }
    `

    const result = graphQLClient.request(query, commentObj)

    setShowSuccessMessage(true);
    setTimeout(() => {
        setShowSuccessMessage(false);
    }, 3000);
  }

  const onInputChange = (e) => {
    // console.log("onInputChange")
    const { target } = e;
    
    if (target.type === 'checkbox') {
      // console.log(target.type, target.value)
      setFormData((prevState) => ({
        ...prevState,
        [target.name]: target.checked,
      }));
    } else {
      // console.log(target.type, target.value)
      setFormData((prevState) => ({
        ...prevState,
        [target.name]: target.value,
      }));
    }
  };

  

  const handleCommentSubmission = () => {

    setError(false)
    setEmailError(false)

    const { name, email, comment, storeData } = formData;

    console.log("name:", name, "\temail:", email, "\tcomment:", comment, "\tstoreData:", storeData)

    if (!comment || !name || !email || comment.trim() === "" || name.trim() === "" || email.trim() === "") {
      setError(true);
      return;
    }

    if (!validator.isEmail(email)) {
      setEmailError(true)
      return;
    }

    const commentObj = {
      name,
      email,
      comment,
      slug,
    };

    if (storeData) {
      localStorage.setItem('name', name);
      localStorage.setItem('email', email);
      localStorage.setItem('storeData', true);
    } else {
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.setItem('storeData', false);
    }

    submitComment(commentObj)
  }

  return (
    <div className="comment-form">
      <h3><b>Enter a Comment</b></h3>

      <Form>
        <Form.Group className="mb-3 form-fields" controlId="commentEl">
          <Form.Label>Enter Comment</Form.Label>
          <Form.Control as="textarea" rows={3} name="comment" placeholder="Enter comment" value={formData.comment} onChange={onInputChange} />
        </Form.Group>

        <Row>

          <Col sm={6}>
            <Form.Group className="mb-3 form-fields" controlId="nameEl">
              <Form.Label>Name</Form.Label>
              <Form.Control type="name" name="name" placeholder="Enter name" value={formData.name} onChange={onInputChange} />
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group className="mb-3 form-fields" controlId="emailEl">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" name="email" placeholder="Enter email" value={formData.email} onChange={onInputChange} />
            </Form.Group>
          </Col>

        </Row>

        <Row>
          <Col sm={1}>
            <Form.Check name="storeData" checked={formData.storeData} onChange={onInputChange} value={!formData.storeData}
              type="checkbox"
            />
          </Col>

          <Col sm={11}>
            <p>Save my email and name for the next time I comment</p>
          </Col>
        </Row>

        <div className='text-center'>
          <Button variant="primary form-component" onClick={handleCommentSubmission}>
            Post Comment
          </Button>
        </div>

        {error && <p className="comment-error-message">All fields are required.</p>}
        {emailError && <p className="comment-error-message">Email is invalid.</p>}
        {showSuccessMessage && <p className="comment-success-message">Comment submitted ✅</p>}
      </Form>




    </div>

  )
}

export default CommentsForm