import logo from '../images/logo2.jpg';
import { Link } from "react-router-dom";
import "./css/style.css";
import Status from './Status';

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        {/* <div className="row">

          <h1>This website is under construction. It is for non-commercial use.</h1>
        </div> */}
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            <img src={logo} id="logo-navbar" alt="Equify logo"/>
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            {/* <li>
              <a href='/#services' className='page-scroll'>
                Why Equify
              </a>
            </li> */}
            <li>
              <Link to='/how-it-works'>
                How it Works
              </Link>
            </li>
            <li>
              <Link to='/pricing'>
                Pricing
              </Link>
            </li>
            <li>
              <Link to='/comparison'>
                Comparison
              </Link>
            </li>
            <li>
              <Link to='/faq'>
                FAQ
              </Link>
            </li>
            <li>
              <Link to='/about-team'>
                About
              </Link>
            </li>
            {/* <li>
              <a href='/#about' className='page-scroll'>
                About
              </a>
            </li> */}
            {/* <li>
              <a href='/#team' className='page-scroll'>
                Team
              </a>
            </li> */}
            <li>
              <a href='/#contact' className='page-scroll'>
                Contact
              </a>
            </li>
            <li>
              <a href='/estimate'>
                Estimate
              </a>
            </li>

            <li>
              <a href='/blog'>
                Blog
              </a>
            </li>

            <li>
              {/* <Link to='/login'>
                <button className='btn btn-custom' style={{ marginTop: '-1.5rem'}}>Login</button>
              </Link> */}
              <Status />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
