import React, { useState, useRef } from 'react';
import logo from '../images/logo2.jpg';
import axios from 'axios';
import { Navigation } from "./navigation";
import { Video } from "./video";
import emailjs from '@emailjs/browser';

function Waitlist() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [province, setProvince] = useState('');
    const [city_region, setCity_region] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [reason, setReason] = useState('');
    const [timeframe, setTimeframe] = useState('');
    const [investmentRange, setInvestmentRange] = useState('');
    let minInvestmentRange = 0;
    let maxInvestmentRange = 0;
    const [stage, setStage] = useState(1);  // 1 = form, 2 = success
    const [errorMessage, setErrorMessage] = useState('');

    function joinWaitlist(e) {
        e.preventDefault();
        switch (investmentRange) {
            case '10to25':
                minInvestmentRange = 10000;
                maxInvestmentRange = 25000;
                break;
            case '25to50':
                minInvestmentRange = 25000;
                maxInvestmentRange = 50000;
                break;
            case '50to100':
                minInvestmentRange = 50000;
                maxInvestmentRange = 100000;
                break;
            case '100+':
                minInvestmentRange = 100000;
                maxInvestmentRange = null;
                break;
            default:
                minInvestmentRange = 0;
                maxInvestmentRange = 0;
        }


        let request = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            province: province,
            city_region: city_region,
            phoneNumber: phoneNumber,
            address: address,
            reason: reason,
            timeframe: timeframe,
            minInvestmentRange: minInvestmentRange,
            maxInvestmentRange: maxInvestmentRange,
            updated_at: Date.now()
        }

        console.log(request);

        axios.post('https://e4xf4v4a5g.execute-api.us-east-1.amazonaws.com/contacts', request)
            .then(resp => {
                console.log("Added " + email + " to waitlist:")
                console.log(resp)
                var templateParams = {
                    user_name: firstName,
                    user_email: email
                };

                emailjs.send('service_0voczkh', 'template_py8cwzb', templateParams, 'UhTz_jp0KTxcxFZ66')
                    .then(function (response) {
                        console.log('SUCCESS!', response.status, response.text);
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
                setStage(2);
            })
            .catch(err => {
                console.log(err);
                setErrorMessage(err.message);
            })
    }

    return (
        <div>
            <Navigation />
            {stage === 1 && (
                <div id='features' className='text-center'>
                    <div className='container' style={{ paddingTop: '100px', display: 'flex' }}>
                            <div className='col-md-10 col-md-offset-1 section-title'>
                                <form onSubmit={(e) => joinWaitlist(e)} style={{ height: '50%', width: '50%', marginLeft: '25%', align: 'center', textAlign: 'center' }}>
                                    <h1>
                                        <img style={{ height: '10rem', width: '25rem' }} src={logo} id="logo-home" alt="Equify logo" />
                                        <span></span>
                                    </h1>
                                    <h3>Join the Waitlist</h3>
                                    <h5>We will contact you when we launch our product!</h5>

                                    <div className="form-group">
                                        <label for="firstName">First Name</label>
                                        <input value={firstName} type="text" id="firstName" className="form-control" placeholder="Enter first name" onChange={(event) => setFirstName(event.target.value)} required />
                                    </div>

                                    <div className="form-group">
                                        <label for="lastName">Last Name</label>
                                        <input value={lastName} type="text" id="lastName" className="form-control" placeholder="Enter last name" required onChange={(event) => setLastName(event.target.value)} />
                                    </div>

                                    <div className="form-group">
                                        <label for="userEmail">Email address</label>
                                        <input value={email} type="email" id="userEmail" className="form-control" placeholder="Enter email" required onChange={(event) => setEmail(event.target.value)} />
                                    </div>

                                    <div className='form-group'>
                                        <label>What province do you live in?</label>
                                        <select value={province} name="province" id="province" required style={{ width: '100%' }} onChange={(event) => setProvince(event.target.value)}>
                                            <option selected disabled hidden value="">Select...</option>
                                            <option value="Alberta">Alberta</option>
                                            <option value="British Columbia">British Columbia</option>
                                            <option value="Manitoba">Manitoba</option>
                                            <option value="New Brunswick">New Brunswick</option>
                                            <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                                            <option value="Nova Scotia">Nova Scotia</option>
                                            <option value="Ontario">Ontario</option>
                                            <option value="Prince Edward Island">Prince Edward Island</option>
                                            <option value="Quebec">Quebec</option>
                                            <option value="Saskatchewan">Saskatchewan</option>
                                            <option value="Northwest Territories">Northwest Territories</option>
                                            <option value="Nunavut">Nunavut</option>
                                            <option value="Yukon Territory">Yukon Territory</option>
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label for="city">What city/region do you live in?</label>
                                        <input value={city_region} type="text" id="city" className="form-control" placeholder="Enter city/region" required onChange={(event) => setCity_region(event.target.value)} />
                                    </div>

                                    <div className='form-group'>
                                        <label>Phone Number</label>
                                        <input
                                            value={phoneNumber}
                                            type='text'
                                            id='phoneNumber'
                                            name='phoneNumber'
                                            className='form-control'
                                            placeholder='e.g. ###-###-####'
                                            required
                                            onChange={(event) => setPhoneNumber(event.target.value)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label for="address">Address</label>
                                        <input value={address} type="text" id="address" className="form-control" placeholder="Enter address" required onChange={(event) => setAddress(event.target.value)} />
                                    </div>

                                    <div className='form-group'>
                                        <label for="reason">How are you most likely to use an Equify Home Equity Investment? *</label>
                                        <select value={reason} name="reason" id="reason" required style={{ width: '100%' }} onChange={(event) => setReason(event.target.value)}>
                                            <option selected disabled hidden value="">Select...</option>
                                            <option value="renovation">Home improvement or repairs</option>
                                            <option value="debt">Pay off debts</option>
                                            <option value="newhome">Purchase a new property</option>
                                            <option value="education">Fund an education</option>
                                            <option value="newcar">Purchase a new Vehicle</option>
                                            <option value="vacation">Take a vacation</option>
                                            <option value="emergency">Pay for emergency expense</option>
                                            <option value="retire">Fund retirement</option>
                                            <option value="newbusiness">Fund a business</option>
                                        </select>
                                    </div>

                                    <div className='form-group'>
                                        <label for="timeframe">If Equify seems like a good fit, when would you want to work with us? *</label>
                                        <select value={timeframe} name="timeframe" id="timeframe" required style={{ width: '100%' }} onChange={(event) => setTimeframe(event.target.value)}>
                                            <option selected disabled hidden value="">Select...</option>
                                            <option value="asap">ASAP</option>
                                            <option value="1to3months">1 to 3 months</option>
                                            <option value="3to6months">3 to 6 months</option>
                                            <option value="6+months">6+ months</option>
                                            <option value="notsure">Not sure yet, still looking</option>
                                        </select>
                                        <div style={{ float: 'left', color: 'red', fontSize: '20px' }}>{errorMessage}</div>
                                    </div>

                                    <div className='form-group'>
                                        <label>What investment range would be most suited for your situation?</label>
                                        <select value={investmentRange} name="province" id="province" required style={{ width: '100%' }} onChange={(event) => setInvestmentRange(event.target.value)}>
                                            <option selected disabled hidden value="">Select...</option>
                                            <option value="10to25">$10,000 to $25,000</option>
                                            <option value="25to50">$25,000 to $50,000</option>
                                            <option value="50to100">$50,000 to $100,000</option>
                                            <option value="100+">$100,000+</option>
                                        </select>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-block">Join Waitlist</button>
                                </form>
                            </div>
                    </div>
                </div>
            )}

            {stage === 2 && (
                <div className='container' style={{ paddingTop: '100px', display: 'flex' }}>
                    <div className='row'>
                        <div className='col-md-10 section-title' style={{ width: '50%', align: 'center', textAlign: 'center' }}>
                            <h1>
                                <img style={{ height: '20%', width: '40%' }} src={logo} id="logo-home" alt="Equify logo" />
                                <span></span>
                            </h1>
                            <h3>Thank you for your interest!</h3>
                            <h5>We will contact you when we launch our product!</h5>
                            <a href="/" className='btn btn-custom btn-lg page-scroll'>Home Page</a>
                        </div>
                    </div>
                </div>
            )}

            <Video />
        </div>
    );
}

export default Waitlist;
