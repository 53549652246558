import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Link } from "react-router-dom";

import { useQuery, gql } from "@apollo/client";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './blog.css'


const recentPostsQuery = gql`
query GetPostDetails {
    posts(
        orderBy: createdAt_ASC
        last: 3
    ) {
      title
      featuredImage {
          url
      }
      createdAt
      slug
    }
}
`;

const getSimilarPosts = (category, slug) => {

}

const PostWidget = () => {
    // const [relatedPosts, setRelatedPosts] = useState([]);

    // useEffect(() => {
    //     if (slug) {
    //         getSimilarPosts(categories, slug)
    //             .then((result) => setRelatedPosts(result))
    //     }
    //     else {
    //         console.log("In use effect")
    //         console.log(data)

    //         setRelatedPosts(data.posts)
    //     }

    // }, [slug])

    let { data, loading, error } = useQuery(recentPostsQuery);

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>

    console.log("Recent Post Data")
    console.log(data)

    const dataCopy = [...data.posts].reverse()

    // data = data.posts.reverse()

    return (
        <div className="widget">
            <h3 className='whiteText'><b>Recent Posts</b></h3>

            {data && data.posts.length > 0 && dataCopy.map((post) => (
                <div key={post.title} className="widget-post">
                    <Row>

                        <Col sm={5}>
                            <img
                                src={post.featuredImage.url}
                                alt={post.title}
                                className='widget-image'
                            />
                        </Col>

                        <Col sm={7}>
                            <p className="widget-date">{moment(post.createdAt).format('MMM DD, YYYY')}</p>
                            <Link to={`/blog/post/${post.slug}`} className='link'>
                                <p>{post.title}</p>
                            </Link>
                        </Col>
                    </Row>

                </div>
            ))}


        </div>


    )
}

export default PostWidget