import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { RangeStepInput } from 'react-range-step-input';
import { Navigation } from "./navigation";

function currencyFormat(num) {
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function DetermineOffer(homeValue, mortgageValue, creditScore) {
  let homeEquity = homeValue - mortgageValue;
  switch (creditScore) {
    case 'good':
      return [homeEquity * 0.15, 0.095, 0.15]
    case 'verygood':
      return [homeEquity * 0.20, 0.085, 0.20]
    case 'excellent':
      return [homeEquity * 0.25, 0.075, 0.25]
    default:
  }

  return homeEquity;
}

function RiskAdjustedValue({ num, riskAdjustment }) {
  return (
    <Card style={{ height: '15rem', border: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '2rem', borderRadius: '20px' }}>
      <Card.Body>
        <h3>Risk-Adjusted Home Value</h3>
        <h4 className="mb-2 text-muted">{currencyFormat(num)}</h4>
        <p>
          Because Equify shares in downside, Equify applies a Risk Adjustment to
          offset small declines in home value and protect its initial investment.
          With your credit score and home equity, Equify will risk adjust by <strong>{riskAdjustment * 100}%</strong>
        </p>
      </Card.Body>
    </Card>
  );
}

function TermSlider({ slider, termLength }) {
  return (
    <Card style={{ height: '16rem', border: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '2rem', borderRadius: '20px' }}>
      <Card.Body>
        <h3>Term Length</h3>
        <p>
          Equify is repaid at the end of the term, when the home is sold,
          or when the homeowner chooses to buy us out.
        </p>
        {slider}
        {termLength + " year(s)"}
        <br></br>
        <small>« Drag Slider »</small>
      </Card.Body>
    </Card>
  );
}

function AvgAppr({ num, investment, termLength, riskAdjustment, equifyShareOfAppr }) {
  let finalHomeValue = (num * Math.pow(1.035, termLength))
  let appr = ((num * Math.pow(1.035, termLength)) - (num * riskAdjustment))
  let rev = (appr * equifyShareOfAppr)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * riskAdjustment)) * equifyShareOfAppr
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let subtitle = <h4>(3.5% / year)</h4>
  if (capped < rev) {
    subtitle = <h4>(3.5% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the amount you’ll pay Equify is capped,
      so you keep more of the upside.</span></h4>
  }
  return (
    <Card style={{ height: '16rem', border: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '5rem', borderRadius: '20px' }}>
      <Card.Body style={{ height: '13rem' }}>
        <h3>Average Appreciation</h3>
        {subtitle}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function NoAppr({ num, investment, termLength, riskAdjustment, equifyShareOfAppr }) {
  let finalHomeValue = (num)
  let appr = ((num) - (num * riskAdjustment))
  let rev = (appr * equifyShareOfAppr)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * riskAdjustment)) * equifyShareOfAppr
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  return (
    <Card style={{ height: '16rem', border: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '5rem', borderRadius: '20px' }}>
      <Card.Body style={{ height: '13rem' }}>
        <h3>No Appreciation</h3>
        <h4>(0% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the only cost you’ll pay Equify is the risk adjustment initially made.</span></h4>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function LargeDeline({ num, investment, termLength, riskAdjustment, equifyShareOfAppr }) {
  let finalHomeValue = (num * Math.pow(0.95, termLength))
  let appr = ((num * Math.pow(0.95, termLength)) - (num * riskAdjustment))
  let rev = (appr * equifyShareOfAppr)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * riskAdjustment)) * equifyShareOfAppr
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let totalCost = equifyShare - investment
  if (totalCost < 0) {
    totalCost = 0
  }
  return (
    <Card style={{ height: '16rem', border: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '5rem', borderRadius: '20px' }}>
      <Card.Body style={{ height: '13rem' }}>
        <h3>Large Decline</h3>
        <h4>(-5% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, you’ll repay Equify less than Equify
          invested in your home because we share in losses.</span></h4>
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(totalCost)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function HighAppr({ num, investment, termLength, riskAdjustment, equifyShareOfAppr }) {
  let finalHomeValue = (num * Math.pow(1.08, termLength))
  let appr = ((num * Math.pow(1.08, termLength)) - (num * riskAdjustment))
  let rev = (appr * equifyShareOfAppr)
  let capped = ((num * Math.pow(1.07, termLength)) - (num * riskAdjustment)) * equifyShareOfAppr
  let equifyShare = (Math.min(...[rev, capped]) + investment)
  let homeShare = (finalHomeValue - equifyShare)
  let subtitle = <h4>(8% / year)</h4>
  if (capped < rev) {
    subtitle = <h4>(8% / year) <br></br><span style={{ color: 'grey' }}>In this scenario, the amount you’ll pay Equify is capped,
      so you keep more of the upside.</span></h4>
  }
  return (
    <Card style={{ height: '16rem', border: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '5rem', borderRadius: '20px' }}>
      <Card.Body style={{ height: '13rem' }}>
        <h3>High Appreciation</h3>
        {subtitle}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroupItem>Future Home Value: <br></br> {currencyFormat(finalHomeValue)}</ListGroupItem>
        <ListGroupItem>Homeowner Keeps: <br></br> {currencyFormat(homeShare)}</ListGroupItem>
        <ListGroupItem>Owe Equify (Principal + Appr.): <br></br> {currencyFormat(equifyShare)}</ListGroupItem>
        <ListGroupItem>Total Cost: <br></br> {currencyFormat(equifyShare - investment)}</ListGroupItem>
      </ListGroup>
    </Card>
  );
}

function InvestmentEstimate() {
  const location = useLocation();
  const [timePeriod, setTimePeriod] = useState(3);

  const onSliderChange = (e) => {
    const newVal = e.target.value;
    setTimePeriod(newVal);
  }

  if (location.state.creditScore === 'poor' || location.state.creditScore === 'fair') {
    return (
      <div>
        <Navigation />
        <div id="estimate" className='text-center' style={{ paddingTop: '100px' }}>
          <div class="container">
            <div className='section-title'>
              <h2>We are sorry {location.state.firstName}!</h2>
              <h4>Based on your credit score, you are unfortunately not eligible for an Equify Home Equity Investment at this time.</h4>
              <h4>You need a credit score of Good or greater in order to qualify</h4>
            </div>
            <div id='estimate' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
              <a href="/" className="btn btn-primary btn-block" style={{ width: '10%', marginRight: '1rem' }}>Home</a>
            </div>
          </div>
        </div>
      </div>
    )
  } else if (((location.state.mortgageValue / location.state.homeValue) * 100) > 70) {
    return (
      <div>
        <Navigation />
        <div id="estimate" className='text-center' style={{ paddingTop: '100px' }}>
          <div class="container">
            <div className='section-title'>
              <h2>We are sorry {location.state.firstName}!</h2>
              <h4>You need to have at least 30% home equity in order to qualify for an Equify Home Equity Investment</h4>
            </div>
            <div id='estimate' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
              <a href="/" className="btn btn-primary btn-block" style={{ width: '10%', marginRight: '1rem' }}>Home</a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  let offer = DetermineOffer(location.state.homeValue, location.state.mortgageValue, location.state.creditScore);
  let riskAdjustVal = location.state.homeValue * (1 - offer[1]);

  return (
    <div>
      <Navigation />
      <div id="estimate" className='text-center' style={{ paddingTop: '100px' }}>
        <div class="container">
          <div className='section-title'>
            <h2>Congratulations {location.state.firstName}!!</h2>
            <h3>Based on your home equity and credit score, <br></br> you are eligible for an Equify Home Equity Investment up to</h3> <h1 style={{ color: 'black', fontWeight: 'bold' }}>{currencyFormat(offer)}</h1>
          </div>
        </div>
      </div>
      <div id='estimate' className='text-center' style={{ paddingBottom: '15rem' }}>
        <div className='container'>
          <div className='row'>
            <div className='portfolio-items'>
              <div className='col-sm-6 col-md-6 col-lg-6'>
                <RiskAdjustedValue num={riskAdjustVal} riskAdjustment={offer[1]} />
              </div>
              <div className='col-sm-6 col-md-6 col-lg-6'>
                <TermSlider slider={<RangeStepInput
                  min={1} max={5}
                  value={timePeriod} step={1}
                  onChange={onSliderChange}
                />
                } termLength={timePeriod} />
              </div>
            </div>
          </div>
        </div>
        <div className='row' style={{ marginLeft: '13rem', marginRight: '13rem', marginTop: '1.5rem', marginBottom: '5rem' }}>
          <div className='col-md-3 col-sm-6 team'>
            <AvgAppr num={location.state.homeValue} investment={offer[0]} termLength={timePeriod} riskAdjustment={1 - offer[1]} equifyShareOfAppr={offer[2]} />
          </div>
          <div className='col-md-3 col-sm-6 team'>
            <NoAppr num={location.state.homeValue} investment={offer[0]} termLength={timePeriod} riskAdjustment={1 - offer[1]} equifyShareOfAppr={offer[2]} />
          </div>
          <div className='col-md-3 col-sm-6 team'>
            <LargeDeline num={location.state.homeValue} investment={offer[0]} termLength={timePeriod} riskAdjustment={1 - offer[1]} equifyShareOfAppr={offer[2]} />
          </div>
          <div className='col-md-3 col-sm-6 team'>
            <HighAppr num={location.state.homeValue} investment={offer[0]} termLength={timePeriod} riskAdjustment={1 - offer[1]} equifyShareOfAppr={offer[2]} />
          </div>
        </div>
      </div>
      <div id='estimate' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
        {/* <a href="/login" className="btn btn-primary btn-block" style={{ width: '10%', marginRight: '1rem' }}>Log In</a>
        <a href="/register" className="btn btn-primary btn-block" style={{ width: '10%', marginBottom: '0.75rem' }}>Register</a> */}
        <div className='col-md-6 text-center' style={{ marginTop: '1%', marginBottom: '1%' }}>
          <h3>Please join the waitlist if you haven't done so already!</h3>
          <a href="/join_waitlist" className='btn btn-custom btn-lg page-scroll'>Join the Waitlist</a>{' '}
        </div>
      </div>
    </div>
  )
}

export default InvestmentEstimate;