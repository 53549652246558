import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Video } from "./video";

import { PostCard, Categories, PostWidget, BlogHeader } from "./BlogComponents";
import './css/globals.scss'
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../App.css";

const postsQuery = gql`
query MyQuery($category: String!) {
    postsConnection(orderBy: publishedAt_DESC, where: {categories_some: {slug: $category}}) {
      edges {
        node {
          author {
            bio
            name
            id
            photo {
              url
            }
          }
          createdAt
          slug
          title
          excerpt
          featuredImage {
            url
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`;


const BlogCategories = () => {
    const category = useParams().category
    console.log("Category: " + category)

    const { data, loading, error } = useQuery(postsQuery, {
        variables: {
            "category": category
        }
    });

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>


    console.log("posts")
    console.log(data.postsConnection.edges)



    return (

        <Container>
            <Navigation />

            {
                data != null && data.postsConnection != null && data.postsConnection.edges != null && data.postsConnection.edges.length > 0 &&
                <div id='portfolio'>

                    <Row>
                        <Col sm={12}>
                            <div className='text-center section-title'>
                                <h2>Category: {category}</h2>
                            </div>
                        </Col>
                    </Row>


                    <Row>

                        <Col sm={9}>
                            {data.postsConnection.edges &&
                                data.postsConnection.edges.map((post) => <PostCard post={post.node} key={post.title} />)
                            }
                        </Col>

                        <Col sm={3}>
                            <PostWidget />
                            <Categories />
                        </Col>
                    </Row>

                </div>
            }

            {
                data == null || data.postsConnection == null || data.postsConnection.edges == null || data.postsConnection.edges.length == 0 &&
                <div id='portfolio'>

                    <Row>
                        <Col sm={12}>
                            <div className='text-center section-title'>
                                <h2>Category: {category}, Posts Coming Soon!</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row>

                        <Col sm={9}></Col>

                        <Col sm={3}>
                            <PostWidget />
                            <Categories />
                        </Col>
                    </Row>
                </div>
            }
        </Container>




    );
};

export default BlogCategories;
