import logo from '../images/logo2.jpg';

export const Contact = (props) => {
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-4'>
            <div className='row'>
              <div className='section-title'>
                <a className='navbar-brand page-scroll' href='/'>
                  <img src={logo} id="logo-navbar" alt="Equify logo" />
                </a>
              </div>
            </div>
            <div className='row' style={{ marginTop: '3rem' }}>
              <p>Get cash by accessing your home's equity. <br></br> No monthly payments, no interest, ever! <br></br> We do well when you do well.</p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {/* {props.data ? props.data.email : 'loading'} */}
                {"equifycontactus@gmail.com"}
              </p>
            </div>
            {/* <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
                {"TBD"}
              </p>
            </div> */}
            {/* <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
                {"TBD"}
              </p>
            </div> */}
          </div>
          <div className='col-md-4'>
            <div className='contact-item'>
              <p>In partnership with <br></br> <strong>Limitless Solutions Financial Group</strong></p>
              <p><strong>FSRA LICENSE: 13207</strong></p>
              <p>All customers must speak to licensed mortgage agent from this brokerage</p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                {/* <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            Copyright &copy; 2022 Equify Inc. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}
