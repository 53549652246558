import React, { useState } from 'react';
import { Navigation } from "./navigation";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import DatePicker from "react-datepicker";

import "./css/style.css";
import "react-datepicker/dist/react-datepicker.css";

function Application() {
    const [startDate, setStartDate] = useState(new Date());

    const data = {
        rows: [
            {
                id: "address",
                question: "Address Question",
                type: `address`,
                answer: ''
            },
            {
                id: "multple-choice",
                question: "multiple choice Question",
                type: `multiple choice`,
                choices: ['option A', 'option B', 'option C', 'option D'],
                answer: ''
            },
            {
                id: "textbox",
                question: "textbox Question",
                type: `textbox`,
                answer: ''
            },
            {
                id: "yes-no",
                question: "Yes/No Question",
                type: `multiple choice`,
                choices: ['Yes', 'No'],
                answer: ''
            },
            {
                id: "date",
                question: "Date Question",
                type: `date`,
                answer: ''
            }
        ],
    };

    const questions = [];

    data.rows.forEach((d) => {
        switch (d.type) {
            case "date":
                questions.push(
                    <div className="form-group" id="question">
                        <p>Type: {d.type}</p>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                )
                break;
            case "address":
                console.log(d);
                questions.push(
                    <div className="form-group" id="question">
                        <p>Type: {d.type}</p>
                        <Autocomplete
                            apiKey={"AIzaSyDBCHx-U1xizn8nNbiyOkU3KsUFVh3WaMM"}
                            onPlaceSelected={(place) => console.log(place)}
                            options={{
                                types: ["address"],
                                componentRestrictions: { country: "ca" },
                            }}
                            defaultValue=""
                            style={{ width: '50%' }}
                        />
                    </div>
                )

                break;
            case "textbox":
                questions.push(
                    <div className="form-group" id="question">
                        <p>Type: {d.type}</p>
                        <label for={d.id}>{d.question}</label>
                        <input id={d.id} type="text" className="form-control" placeholder="Enter textbox answer" required />
                    </div>
                )
                break;
            case "multiple choice":
                let choices = []

                d.choices.forEach((choice, i) => {
                    choices.push(
                        <div>
                            <div>
                                <Dropdown.Item>{choice}</Dropdown.Item>
                            </div>
                        </div>
                    )
                })

                questions.push(
                    <div className="form-group" id="question">
                        <h3>{d.question}</h3>
                        <p>{d.type}</p>
                        <DropdownButton id="dropdown-item-button" title="Dropdown button">
                            {choices}
                        </DropdownButton>
                    </div>
                )
                break;
            default:
        }
    });

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    {/* <h1 className='text-center'>Application</h1>
                    <div className='container'>
                        <div className='row'>
                            <form onSubmit={signUp}>
                                {
                                    questions
                                }

                                <button type="submit" className="btn btn-primary btn-block">Submit Application</button>
                            </form>
                        </div>
                    </div> */}
                    <div className='row text-center'>
                        <h1>COMING SOON</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Application;
