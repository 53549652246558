import React from 'react'
import moment from 'moment';
import { Link } from "react-router-dom";

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import './blog.css'

const PostCard = ({ post }) => {
    console.log("PostCard")
    console.log(post)
    return (
        <div className='postcard'>
            <div className='text-center'>
                <img
                    src={post.featuredImage.url}
                    alt={post.title}
                    className='postcard-main-image'
                />
                <h1>
                    <Link to={`/blog/post/${post.slug}`} className='link'>
                    <b>{post.title}</b>
                    </Link>
                </h1>
                <span>
                    <img
                        src={post.author.photo.url}
                        alt={post.author.name}
                        className='postcard-author-image'
                    />
                    Author: {post.author.name}
                </span>


                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" className="calendar-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>

                    {moment(post.createdAt).format('MMM DD, YYYY')}
                </p>

                <p>{post.excerpt}</p>

                <Link to={`/blog/post/${post.slug}`}>
                    <Button variant="primary">Continue Reading</Button>
                </Link>
            </div>
        </div>


    )
}

export default PostCard