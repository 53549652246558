import React, { useState, useContext } from 'react';
import { Navigation } from "./navigation";
import { Table } from 'react-bootstrap';
import { AccountContext } from "./Account";
import 'react-initials-avatar/lib/ReactInitialsAvatar.css';

function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const { getSession } = useContext(AccountContext);

    getSession().then(({ user }) => {
        user.getUserAttributes(function (err, result) {
            if (err) {
                return;
            } else {
                for (let i = 0; i < result.length; i++) {
                    if (result[i].getName() === 'name') {
                        setFirstName(result[i].getValue());
                    } else if (result[i].getName() === 'family_name') {
                        setLastName(result[i].getValue());
                    } else if (result[i].getName() === 'email') {
                        setEmail(result[i].getValue());
                    }
                }
            }
        });
    });

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row'>
                    <div className='col-md-12 text-center' style={{width: '40rem', marginLeft: '40rem'}}>
                        <h3>User Profile</h3>
                        <svg style={{ marginBottom: '1rem' }} height="150" width="150">
                            <circle cx="75" cy="75" r="70" stroke="black" stroke-width="2" fill="grey" />
                            <text x="50%" y="50%" text-anchor="middle" fill="#fff" dy=".3em">
                                <tspan x="50%" dy='0.3em' style={{fontSize: '50px'}}>{firstName.charAt(0)}{lastName.charAt(0)}</tspan>
                            </text>
                        </svg>
                        <Table responsive="sm">
                            <tbody>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Name</td>
                                    <td>{firstName} {lastName}</td>
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Email</td>
                                    <td>{email}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <a href="/dashboard" style={{ width: '20rem', display: 'inline-block' }} className="btn btn-primary btn-block">View Dashboard</a>
                    </div>
                    <a href="/settings" style={{ width: '10rem', float: 'right' }} className="btn btn-primary btn-block">Settings</a>
                </div>
            </div>
        </div>
    );
}

export default Profile;
