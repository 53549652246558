import React, { useState, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useNavigate, useLocation } from "react-router-dom";
import { Navigation } from "./navigation";
import axios from 'axios';

function Estimate() {
  const nameForm = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  let defaultHomeValue = 0;
  const [homeValueErrorMessage, setHomeValueErrorMessage] = useState('Please enter an estimated home value.');
  const [mortgageValueErrorMessage, setMortgageValueErrorMessage] = useState('');
  const [currencyFieldsConfirmed, setCurrencyFieldsConfirmed] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [reason, setReason] = useState('');
  const [timeframe, setTimeframe] = useState('');
  const [creditScore, setCreditScore] = useState('');

  // This means that the user is coming from the pricing page and has inputted their home value there
  if (location.state != null) {
    defaultHomeValue = location.state.homeValue;
    setHomeValueErrorMessage('');
  }

  const handleHomeValueChange = (value) => {
    if (Number.isNaN(Number(value)) || value <= 0) {
      setHomeValueErrorMessage('Please enter an estimated home value.');
      setCurrencyFieldsConfirmed(false);
      return;
    }
    setHomeValueErrorMessage('');
    setCurrencyFieldsConfirmed(true);
  };

  const handleMortgageValueChange = (value) => {
    // Mortgage value CAN be 0
    if (Number.isNaN(Number(value)) || value < 0) {
      setMortgageValueErrorMessage('Please enter an estimated mortgage value.');
      return;
    }
    setMortgageValueErrorMessage('');
  };

  const handleSubmit = () => {
    const form = nameForm.current
    let mortgageValue = parseFloat(`${form['mortgagevalue'].value}`.replace(/[$,]+/g, ""));
    if (Number.isNaN(Number(mortgageValue))) {
      mortgageValue = 0;
    }
    let data = {
      homeValue: parseFloat(`${form['homevalue'].value}`.replace(/[$,]+/g, "")),
      mortgageValue: mortgageValue,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNum: phoneNumber,
      creditScore: creditScore,
      reason: reason,
      timeframe: timeframe,
      updated_at: Date.now()
    };

    // Make call to AWS to store user info
    if (form['collectdata'].checked) {
      console.log("Sending data to AWS");
      axios.post('https://e4xf4v4a5g.execute-api.us-east-1.amazonaws.com/estimate-leads', data)
        .then(resp => {
          console.log(resp)
        })
        .catch(err => {
          console.log(err);
        })
    }

    navigate('/investment-estimate', { state: data });
  }

  return (
    <div>
      <Navigation />
      <div id="estimate" className='text-center' style={{ paddingTop: '100px' }}>
        <div class="container">
          <div className='section-title'>
            <h2>Investment Estimate</h2>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem' }}>
              <form ref={nameForm} validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '50%', textAlign: 'left' }}>Estimated Home Value *</h5>
                      {/* <CurrencyField name={'homevalue'} defaultValue={defaultHomeValue} /> */}
                      <div>
                        <div>
                          <CurrencyInput
                            id="homevalue-input"
                            name={'homevalue'}
                            defaultValue={defaultHomeValue}
                            decimalsLimit={2}
                            onValueChange={handleHomeValueChange}
                            prefix='$'
                            allowNegativeValue={false}
                            className="form-control form-control-lg"
                            style={{ float: 'right', textAlign: 'center', fontSize: '25px', color: 'black', width: '20rem', marginBottom: '5px' }}
                          />
                        </div>
                        <div className="invalid-feedback" style={{ float: 'right', color: 'red' }}>{homeValueErrorMessage}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '50%', textAlign: 'left' }}>Estimated Mortgage Balance on Home *</h5>
                      {/* <CurrencyField name={'mortgagevalue'} defaultValue={0} /> */}
                      <div>
                        <div>
                          <CurrencyInput
                            id="mortgagevalue-input"
                            name={'mortgagevalue'}
                            defaultValue={0}
                            decimalsLimit={2}
                            onValueChange={handleMortgageValueChange}
                            prefix='$'
                            allowNegativeValue={false}
                            className="form-control form-control-lg"
                            style={{ float: 'right', textAlign: 'center', fontSize: '25px', color: 'black', width: '20rem', marginBottom: '5px' }}
                          />
                        </div>
                        <div className="invalid-feedback" style={{ float: 'right', color: 'red' }}>{mortgageValueErrorMessage}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left' }}>First Name *</h5>
                      <input
                        type='text'
                        id='first-name'
                        name='first-name'
                        value={firstName}
                        onChange={(event) => setFirstName(event.target.value)}
                        className='form-control'
                        placeholder='First'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left' }}>Last Name *</h5>
                      <input
                        type='text'
                        id='last-name'
                        name='last-name'
                        value={lastName}
                        onChange={(event) => setLastName(event.target.value)}
                        className='form-control'
                        placeholder='Last'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left' }}>Email *</h5>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className='form-control'
                        placeholder='Email'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left' }}>Phone Number *</h5>
                      <input
                        type='text'
                        id='phonenum'
                        name='phonenum'
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        className='form-control'
                        placeholder='e.g. ###-###-####'
                        required
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '100%' }}>Credit Score Range *</h5>
                      <br></br>
                      <select name="creditscore" id="creditscore" value={creditScore} onChange={(event) => setCreditScore(event.target.value)} required style={{ width: '75%' }}>
                        <option selected disabled hidden value="">Select...</option>
                        <option value="poor">Poor (300 - 559)</option>
                        <option value="fair">Fair (560 - 659)</option>
                        <option value="good">Good (660 - 724)</option>
                        <option value="verygood">Very Good (725 - 759)</option>
                        <option value="excellent">Excellent (760+)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '100%' }}>How are you most likely to use an Equify Home Equity Investment? *</h5>
                      <select name="reason" id="reason" value={reason} onChange={(event) => setReason(event.target.value)} required style={{ width: '75%' }}>
                        <option selected disabled hidden value="">Select...</option>
                        <option value="renovation">Home improvement or repairs</option>
                        <option value="debt">Pay off debts</option>
                        <option value="newhome">Purchase a new property</option>
                        <option value="education">Fund an education</option>
                        <option value="newcar">Purchase a new Vehicle</option>
                        <option value="vacation">Take a vacation</option>
                        <option value="emergency">Pay for emergency expense</option>
                        <option value="retire">Fund retirement</option>
                        <option value="newbusiness">Fund a business</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '100%' }}>If Equify seems like a good fit, when would you want to work with us? *</h5>
                      <select name="timeframe" id="timeframe" value={timeframe} onChange={(event) => setTimeframe(event.target.value)} required style={{ width: '75%' }}>
                        <option selected disabled hidden value="">Select...</option>
                        <option value="asap">ASAP</option>
                        <option value="1to3months">1 to 3 months</option>
                        <option value="3to6months">3 to 6 months</option>
                        <option value="6+months">6+ months</option>
                        <option value="notsure">Not sure yet, still looking</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <h5 style={{ float: 'left', width: '100%' }} for="collectdata">Would you like to receive emails and news from Equify?<input style={{ marginLeft: '5px', width: '15px', height: '15px' }} type="checkbox" id="collectdata" name="collectdata"></input></h5><br></br>
                    </div>
                  </div>
                </div>
                <button type='submit' disabled={!currencyFieldsConfirmed} className='btn btn-custom btn-lg page-scroll' style={{ marginTop: '1.5rem' }}>Get Estimation</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Estimate;