import { useState, useEffect } from "react";
import { Navigation } from "./navigation";
import { Header } from "./header";
import { Video } from "./video";

import { PostCard, Categories, PostWidget, BlogHeader } from "./BlogComponents";
import './css/globals.scss'
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../App.css";

const postsQuery = gql`
  query MyQuery {
      postsConnection(orderBy: publishedAt_DESC) {
      edges {
          node {
          author {
                      bio
                      name
                      id
              photo {
                          url
                      }
                  }
                  createdAt
                  slug
                  title
                  excerpt
          featuredImage {
                      url
                  }
          categories {
                      name
                      slug
                  }
              }
          }
      }
  }
`;


const Blog = () => {
    const { data, loading, error } = useQuery(postsQuery);

    if (loading) return "Loading...";
    if (error) return <pre>{error.message}</pre>


    console.log("posts")
    console.log(data.postsConnection.edges)

    return (

        <Container>
            <Navigation />

            <div id='portfolio'>

                <Row>
                    <Col sm={4}></Col>

                    <Col sm={4}>
                        <div className='text-center section-title'>
                            <h2>Blog</h2>
                        </div>
                    </Col>

                    <Col sm={4}>
                        {/* <div className='text-center section-title'>
                            {categories.map((category) => (
                                <Link to={`/blog/category/${category.slug}`}>
                                    <span style={{ marginRight: '10px', fontSize: '17' }} className="menu navigation-menu">
                                        {category.name}
                                    </span>
                                </Link>
                            ))}
                        </div> */}
                    </Col>
                </Row>


                <Row>

                    <Col sm={9}>
                        {data.postsConnection.edges &&
                            data.postsConnection.edges.map((post) => <PostCard post={post.node} key={post.title} />)
                        }
                    </Col>

                    {/* <Col md={5}></Col> */}

                    <Col sm={3}>
                        <PostWidget />
                        <Categories />
                    </Col>
                </Row>

            </div>
        </Container>


    );
};

export default Blog;
