import React, { createContext } from 'react';
import Pool from '../UserPool';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

const AccountContext = createContext();

const Account = (props) => {
    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession(async (err, session) => {
                    if (err) {
                        reject();
                    }
                    else {
                        const attributes = new Promise((resolve, reject) => {
                            user.getUserAttributes((err, attributes) => {
                                if (err) {
                                    reject(err);
                                } else {
                                    const results = {}

                                    for (let attribute of attributes) {
                                        const { Name, Value } = attribute;
                                        results[Name] = Value;
                                    }
                                    resolve(results);
                                }
                            });
                        });

                        resolve({ user, session, attributes });
                    }
                });
            }
        });
    };


    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {
            const cognitoUser = new CognitoUser({ Username, Pool });

            const authenticationDetails = new AuthenticationDetails({ Username, Password });

            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    var accessToken = result.getAccessToken().getJwtToken();

                    /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
                    var idToken = result.idToken.jwtToken;

                    resolve(result)
                },

                onFailure: function (err) {
                    //alert(err);
                    console.log(err)
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired: ", data)
                    resolve(data)
                }
            });
        });

    };

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            console.log("Logged Out user")
            user.signOut();
        }
    };

    return (
        <AccountContext.Provider value={{ authenticate, getSession, logout }}>
            {props.children}
        </AccountContext.Provider>
    );

};
export { Account, AccountContext };