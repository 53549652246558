import { useState, useEffect } from "react";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/home";
import Pricing from "./components/Pricing";
import Comparison from "./components/Comparison";
import Estimate from "./components/Estimate";
import Login from "./components/Login"
import { Faq } from "./components/faq";
import { HowItWorks } from "./components/howItWorks";
import { AboutTeam } from "./components/aboutTeam";
import ScrollToTop from "./components/ScrollToTop";
import InvestmentEstimate from "./components/InvestmentEstimate";
import Register from "./components/Register";
import Waitlist from "./components/Waitlist";
import ConfirmRegistration from "./components/ConfirmRegistration";
import { Account } from "./components/Account";
import ForgotPassword from "./components/ForgotPassword";
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import Application from "./components/Application";
import Documents from "./components/Documents";
import Settings from "./components/Settings";
import Blog from "./components/blog";
import { Post } from "./components/BlogComponents";

import BlogCategories from "./components/blogCategories";

import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHCMS_ENDPOINT,
    cache: new InMemoryCache()
});


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Account>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/faq" element={<Faq data={landingPageData.Testimonials} />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/comparison" element={<Comparison />} />
            <Route path="/estimate" element={<Estimate />} />
            <Route path="/about-team" element={<AboutTeam />} />
            <Route path="/login" element={<Login />} />
            <Route path="/investment-estimate" element={<InvestmentEstimate />} />
            <Route path="/register" element={<Register />} />
            <Route path="/join_waitlist" element={<Waitlist />} />
            <Route path="/confirm" element={<ConfirmRegistration />} />
            <Route path="/forgot_password" element={<ForgotPassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/application" element={<Application />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/post/:slug" element={<Post />} />
            <Route path="/blog/category/:category" element={<BlogCategories />} />
          </Routes>
        </Router>
      </Account>
    </ApolloProvider>
  );
};

export default App;
