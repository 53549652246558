import "./css/style.css";

export const Video = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>How it Works</h2>
        </div>
        <div className='row'>
          <iframe style={{ height: '50%', width: '75%', marginBottom: '5%', borderRadius: '2%' }}
            src="https://www.youtube.com/embed/jPtjI8zCdyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
        </div>
      </div>
    </div>
  )
}
