import React from 'react';
import { Navigation } from "./navigation";

function Documents() {

    return (
        <div>
            <Navigation />
            <div className='container' style={{ paddingTop: '100px' }}>
                <div className='row text-center'>
                    <h1>COMING SOON</h1>
                </div>
            </div>
        </div>
    );
}

export default Documents;